import yup from "src/helpers/validation";
import { gql } from "@apollo/client";
import { ExcelRow } from "src/types";
import request from "./request";
import { Category } from "./categories";
import { Supplier } from "./suppliers";
import { Company } from "./companies";
import { User } from "./users";
import { formatCurrency } from "src/helpers/numbers";
import { PriceListItem } from "./price-lists";

export type ProductAudit = {
  id: number;
  action: string;
  user: Pick<User, "name" | "lastname">;
  from: string;
  to: string;
  date: Date;
};

export type ProductBarcode = {
  id: number;
  type: string;
  barcode: string;
};

export type Product = {
  id: number;
  productId: number;
  name: string;
  sku: string;
  companyId: number;
  currentStock: number;
  supplierId: number | null;
  categoryId: number | null;
  description: string | null;

  stockeable: boolean;
  stockAlert: number;
  productExpires: boolean;
  multipleBarcodes: boolean;

  cost: number;
  price: number;
  profit: number;
  tax: number;
  updatedAt: string;
  deletedAt: string | null;

  category?: Category;
  supplier?: Supplier;
  company?: Partial<Company>;
  audits: ProductAudit[];
  connections: PriceListItem[];
  barcodes: ProductBarcode[];
};

export type ExcelFile = {
  filename: string;
  header: ExcelRow[];
  title: ExcelRow[];
  products: Product[];
};

type DataSet = {
  categories: Category[];
  suppliers: Supplier[];
};

const AuditKeys = {
  name: {
    label: "Nombre",
    formatFn: (v: any) => v,
  },
  barcode: {
    label: "Código de barra",
    formatFn: (v: any) => v,
  },
  sku: {
    label: "SKU",
    formatFn: (v: any) => v,
  },
  supplierId: {
    label: "Proveedor",
    formatFn: (v: any, d: DataSet) =>
      d.suppliers.find((s) => Number(s.id) === Number(v))?.name,
  },
  categoryId: {
    label: "Categoría",
    formatFn: (v: any, d: DataSet) =>
      d.categories.find((s) => Number(s.id) === Number(v))?.name,
  },
  stockeable: {
    label: "¿Maneja Stock?",
    formatFn: (v: boolean) => (v ? "Si" : "No"),
  },
  productExpires: {
    label: "¿Producto con vencimiento?",
    formatFn: (v: boolean) => (v ? "Si" : "No"),
  },
  multipleBarcodes: {
    label: "¿Producto con multiples códigos de barra?",
    formatFn: (v: boolean) => (v ? "Si" : "No"),
  },
  cost: {
    label: "Costo",
    formatFn: (v: any) => formatCurrency(v),
  },
  profit: {
    label: "Ganancia",
    formatFn: (v: any) => `${v} %`,
  },
  tax: {
    label: "IVA",
    formatFn: (v: any) => `${v} %`,
  },
  images: {
    label: "Imagen",
    formatFn: (v: string[]) => v ?? [],
  },
  price: {
    label: "Precio",
    formatFn: (v: any) => formatCurrency(v),
  },
};

export const mapProductAudit = (audit: ProductAudit, dataset) => {
  if (audit.action !== "UPDATE") {
    return [];
  }

  const from = JSON.parse(audit.from);
  const to = JSON.parse(audit.to);

  const changes: {
    key: string;
    label: string;
    prevValue: any;
    nextValue: any;
  }[] = [];

  Object.entries(to).forEach(([key, value]) => {
    if (key === "deletedAt" || key === "metadata") {
      return;
    }

    const prevValue = from[key] as any;
    const nextValue = value as any;
    const AuditKey = AuditKeys[key];

    changes.push({
      key,
      label: AuditKey.label,
      prevValue: AuditKey.formatFn(prevValue, dataset),
      nextValue: AuditKey.formatFn(nextValue, dataset),
    });
  });

  return changes;
};

export const taxes: Tax[] = [
  {
    id: 1,
    value: 0,
  },
  {
    id: 2,
    value: 10.5,
  },
  {
    id: 3,
    value: 21,
  },
];

export type Tax = { id: number; value: number };

export type CreateProduct = Omit<
  Product,
  | "id"
  | "description"
  | "productId"
  | "deletedAt"
  | "company"
  | "updatedAt"
  | "currentStock"
  | "audits"
  | "connections"
  | "barcodes"
> & {
  force?: boolean;
  barcodeType: ProductBarcode["type"];
  barcode: ProductBarcode["barcode"];
};

export type UpdateProduct = Omit<
  Product,
  "id" | "barcodes" | "companyId" | "updatedAt" | "deletedAt"
> & {
  barcodeType: ProductBarcode["type"];
  barcode: ProductBarcode["barcode"];
};

export const ProductSchema = yup.object().shape({
  name: yup.string().required(),
  barcode: yup.string(),
  cost: yup.string().numeric(),
  price: yup.string().numeric().required(),
  tax: yup.string().required(),
});

export const LIST_PRODUCTS = gql`
  query getProducts($filters: ProductFilters) {
    data: products(filters: $filters) {
      data {
        id
        name
        sku
        currentStock
        productExpires
        multipleBarcodes
        category {
          id
          name
        }
        supplier {
          id
          name
        }
        connections {
          priceList {
            store {
              type
            }
          }
        }

        barcodes {
          barcode
        }

        cost
        profit
        price
        updatedAt
        deletedAt
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query getProduct($id: Int!, $trashed: Boolean) {
    data: product(id: $id, trashed: $trashed) {
      id
      name
      sku
      companyId
      stockeable
      stockAlert
      productExpires
      multipleBarcodes
      images
      category {
        id
        name
      }
      supplier {
        id
        name
      }
      connections {
        priceList {
          id
          name

          store {
            id
            name
            type
          }
        }

        metadata {
          tiendaNubeUrl
          mercadoLibreUrl
          mercadoShopsUrl
        }
      }
      barcodes {
        id
        barcode
        type
      }
      cost
      profit
      tax
      price
      deletedAt
    }
  }
`;

export const LIST_AUDITS = gql`
  query productAudits($id: Int!) {
    data: productAudits(id: $id) {
      id
      action
      date
      from
      to
      user {
        name
        lastname
      }
    }
  }
`;

export const LAST_CHANGE = gql`
  query productAudit($id: Int!) {
    data: productAudit(id: $id) {
      action
      date
      from
      to
    }
  }
`;

export const EXPORT_PRODUCTS = gql`
  query exportProducts($filters: ExportProductsFilters!) {
    data: exportProducts(filters: $filters) {
      filename
      header {
        value
        span
      }
      title {
        value
        align
      }
      products {
        id
        sku
        name
        description
        barcodes {
          barcode
        }
        supplier {
          id
          name
        }
        category {
          id
          name
        }
        cost
        profit
        price
        tax
        updatedAt
      }
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($input: CreateProductInput!) {
    data: createProduct(input: $input) {
      id
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: Int!, $input: UpdateProductInput!) {
    data: updateProduct(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: Int!) {
    data: deleteProduct(id: $id) {
      id
    }
  }
`;

export const CREATE_BARCODE = gql`
  mutation createProductBarcode(
    $productId: Int!
    $barcode: String!
    $type: String
  ) {
    data: createProductBarcode(
      productId: $productId
      barcode: $barcode
      type: $type
    ) {
      id
    }
  }
`;

export const DELETE_BARCODE = gql`
  mutation deleteProductBarcode($id: Int!) {
    data: deleteProductBarcode(id: $id) {
      id
    }
  }
`;

export const importProducts = (body: any) =>
  request.post(`/imports/products`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const importSupplierProducts = (supplierId: number, body: any) =>
  request.post(`/imports/suppliers/${supplierId}/products`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const importCategoryProducts = (categoryId: number, body: any) =>
  request.post(`/imports/categories/${categoryId}/products`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const categoriesBatchCostUpdate = (
  companyId: number,
  categoryId: number,
  value: number
) =>
  request.patch(`/imports/categories/${categoryId}/products`, {
    companyId,
    value,
  });

export const suppliersBatchCostUpdate = (
  companyId: number,
  supplierId: number,
  value: number
) =>
  request.patch(`/imports/suppliers/${supplierId}/products`, {
    companyId,
    value,
  });

export const categoriesBatchStockUpdate = (
  companyId: number,
  categoryId: number,
  value: number
) =>
  request.patch(`/imports/categories/${categoryId}/products/stock`, {
    companyId,
    value,
  });

export const suppliersBatchStockUpdate = (
  companyId: number,
  supplierId: number,
  value: number
) =>
  request.patch(`/imports/suppliers/${supplierId}/products/stock`, {
    companyId,
    value,
  });

export const batchCostUpdate = (companyId: number, value: number) =>
  request.patch(`/imports/products`, {
    companyId,
    value,
  });
