import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CCol,
  CRow,
  CFormCheck,
  CLoadingButton,
} from "@coreui/react-pro";
import Api from "src/api";
import { useQuery } from "@apollo/client";
import { GraphQLMeta } from "src/types";
import { PriceList } from "src/api/price-lists";

export type PropagateModalForwardedRef = {
  open: (productIds: number[]) => void;
  close: () => void;
};

export type PropagateModalProps = {
  title: string;
  subtitle: string;
  text: string;
};

const PropagateModal: ForwardRefRenderFunction<
  PropagateModalForwardedRef,
  PropagateModalProps
> = ({ title, text, subtitle }, ref) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [priceListIds, setPriceListIds] = useState<number[]>([]);
  const [productIds, setProductIds] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { data: priceLists } = useQuery<GraphQLMeta<PriceList>>(
    Api.PriceLists.LIST_PRICE_LISTS,
    {
      skip: !showModal,
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          type: "ACTIVE",
        },
      },
    }
  );

  const propagateRequest = async (plIds: number[], prIds: number[]) => {
    try {
      setLoading(true);

      if (plIds.length === 0) {
        setLoading(false);

        window.location.reload();
      }

      await Api.PriceLists.propagateFromImport(plIds, prIds);

      alert("Los productos han sido modificados en las listas de precios");

      window.location.reload();
    } catch (e) {
      alert("Ha habido un error al propagar los precios");
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      open: (prodIds: number[]) => {
        setShowModal(true);
        setProductIds(prodIds);
      },
      close: () => {
        setShowModal(false);
        setProductIds([]);
      },
    }),
    []
  );

  const togglePriceList = (id: number) => {
    setPriceListIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((priceListId) => priceListId !== id);
      }

      return [...prev, id];
    });
  };

  const updatePriceLists = () => {
    if (!loading) {
      propagateRequest(priceListIds, productIds);
    }
  };

  const filteredLists = useMemo(() => {
    if (!priceLists?.data?.data) {
      return [];
    }

    return priceLists.data.data ?? [];
  }, [priceLists?.data?.data]);

  useEffect(() => {
    if (filteredLists.length > 0) {
      setPriceListIds(filteredLists.map((list) => list.id));
    }
  }, [filteredLists]);

  return (
    <CModal
      alignment="center"
      size="lg"
      visible={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      backdrop="static"
      keyboard={false}
    >
      <CModalHeader closeButton={false}>{title}</CModalHeader>
      <CModalBody>
        {subtitle}
        {(priceLists?.data.data.length ?? 0) > 0 && (
          <>
            <br />
            <br />
            {text}
            <CRow className="mt-3">
              <CCol md="12" className="mt-2 mb-3">
                {filteredLists.map((priceList) => {
                  return (
                    <CFormCheck
                      key={priceList.id}
                      inline
                      className="w-full"
                      id={`priceList-${priceList.id}`}
                      value={priceList.id}
                      checked={priceListIds.includes(priceList.id)}
                      label={`${priceList.store?.name} - ${priceList?.name}`}
                      name={`priceList-${priceList.id}`}
                      onChange={() => togglePriceList(priceList.id)}
                    />
                  );
                })}
              </CCol>
            </CRow>
          </>
        )}
      </CModalBody>
      <CModalFooter>
        <CButton
          size="sm"
          type="button"
          color="secondary"
          disabled={loading}
          onClick={() => {
            setShowModal(false);

            window.location.reload();
          }}
        >
          Cancelar
        </CButton>
        <CLoadingButton
          size="sm"
          type="button"
          loading={loading}
          disabled={loading}
          onClick={() => updatePriceLists()}
          color="success"
        >
          Continuar
        </CLoadingButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(PropagateModal);
