import { add } from "date-fns/add";
import { isAfter } from "date-fns/isAfter";
import { isBefore } from "date-fns/isBefore";
import { PaymentStatus } from "src/api/companies";
import { Plan, PlanType, StorePlan } from "src/api/plans";
import Icon, {
  Afip,
  MercadoLibre,
  MercadoPago,
  MercadoShops,
  TiendaNube,
} from "src/components/Icon";

export const parseStatus = (
  status: PaymentStatus,
  dateFrom?: Date,
  dateTo?: Date,
  onlyStatus?: boolean
) => {
  switch (status) {
    case PaymentStatus.PENDING:
      if (dateFrom && isBefore(new Date(), new Date(dateFrom))) {
        return "Próximo";
      }

      if (dateTo && isAfter(new Date(), new Date(dateTo))) {
        return "Pasado";
      }

      if (
        dateFrom &&
        isAfter(new Date(), add(new Date(dateFrom), { days: 14 }))
      ) {
        return "Deuda";
      }

      if (
        dateFrom &&
        isAfter(new Date(), add(new Date(dateFrom), { days: 10 }))
      ) {
        return "Vencido";
      }

      if (
        dateFrom &&
        isAfter(new Date(), add(new Date(dateFrom), { days: 7 }))
      ) {
        return "Proximo a Vencer";
      }

      return "Activo";

    case PaymentStatus.DEBTOR:
      return "Vencido";

    case PaymentStatus.CANCELLED:
      return "Cancelado";

    case PaymentStatus.PAID:
      if (
        !onlyStatus &&
        dateFrom &&
        isAfter(new Date(), new Date(dateFrom)) &&
        dateTo &&
        isBefore(new Date(), new Date(dateTo))
      ) {
        return "Activo y Pago";
      }

      if (!onlyStatus && dateTo && isAfter(new Date(), new Date(dateTo))) {
        return "Pasado";
      }

      return "Pagado";

    case PaymentStatus.FUTURE:
      return "Futuro";

    default:
    case PaymentStatus.UNPAID:
      return "Impago";
  }
};

export const parseStatusBadge = (
  status: PaymentStatus,
  dateFrom?: Date,
  dateTo?: Date
) => {
  switch (status) {
    case PaymentStatus.PENDING:
      if (dateTo && isAfter(new Date(), new Date(dateTo))) {
        return "info";
      }

      if (
        dateFrom &&
        isAfter(new Date(), add(new Date(dateFrom), { days: 14 }))
      ) {
        return "danger";
      }

      if (
        dateFrom &&
        isAfter(new Date(), add(new Date(dateFrom), { days: 10 }))
      ) {
        return "warning";
      }

      return "primary";

    case PaymentStatus.DEBTOR:
      return "dark";

    case PaymentStatus.CANCELLED:
      return "danger";

    case PaymentStatus.PAID:
      if (
        dateFrom &&
        isAfter(new Date(), new Date(dateFrom)) &&
        dateTo &&
        isBefore(new Date(), new Date(dateTo))
      ) {
        return "success";
      }

      return "dark";

    case PaymentStatus.FUTURE:
      return "info";

    default:
    case PaymentStatus.UNPAID:
      return "warning";
  }
};

export const findPlan = (plan: StorePlan | Plan) =>
  planTypes.find(
    (t) => t.id === (plan as StorePlan).type || t.id === (plan as Plan).key
  ) ?? planTypes.find((t) => t.id === PlanType.Entrepeneur);

export const planTypes = [
  {
    id: PlanType.Afip,
    label: "Tienda Fisica",
    icon: <Afip color="#000" size={24} />,
  },
  {
    id: PlanType.TiendaNube,
    label: "Tienda Nube",
    icon: <TiendaNube size={24} />,
  },
  {
    id: PlanType.MercadoPago,
    label: "Mercado Pago",
    icon: <MercadoPago size={24} />,
  },
  {
    id: PlanType.MercadoLibre,
    label: "Mercado Libre",
    icon: <MercadoLibre size={24} />,
  },
  {
    id: PlanType.MercadoShops,
    label: "Mercado Shops",
    icon: <MercadoShops size={24} />,
  },
  {
    id: PlanType.Entrepeneur,
    label: "Emprendedor",
    icon: <Icon color="black" name="building-storefront" />,
  },
  {
    id: PlanType.Pymes,
    label: "Pymes",
    icon: <Icon color="black" name="building-storefront" />,
  },
  {
    id: PlanType.Pro,
    label: "Pro",
    icon: <Icon color="black" name="building-storefront" />,
  },
  {
    id: PlanType.Plus,
    label: "Plus",
    icon: <Icon color="black" name="building-storefront" />,
  },
];

export const checkPayments = (plans: StorePlan[]) =>
  plans.every(
    (storePlan) =>
      storePlan.payedAt || storePlan.price - storePlan.discount === 0
  );
