import {
  CCol,
  CRow,
  CFormInput,
  CFormLabel,
  CFormCheck,
} from "@coreui/react-pro";
import { adminUserAccess, userAccess } from "src/api/users";
import { getValidity } from "src/helpers/validation";
import { useAdminStore } from "src/store";

const UserDetail = ({ user, formik, itsMe }) => {
  const { user: currentUser } = useAdminStore();
  const handleAccessChange = (value: string, checked: boolean) => {
    if (checked) {
      formik.setFieldValue("userAccess", [...formik.values.userAccess, value]);
    } else {
      formik.setFieldValue(
        "userAccess",
        formik.values.userAccess.filter((item) => item !== value)
      );
    }
  };

  return (
    <>
      <CCol xs="12">
        <CRow className="form-row h-9">
          <CCol md="3">
            <CFormLabel>Email</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <p className="form-control-static">{user.email}</p>
          </CCol>
        </CRow>
        <CRow className="form-row">
          <CCol md="3">
            <CFormLabel htmlFor="name">Nombre</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              defaultValue={user.name}
              id="name"
              placeholder="Nombre"
              name="name"
              onChange={formik.handleChange}
              {...getValidity(formik.values.name, formik.errors.name)}
            />
          </CCol>
        </CRow>
        <CRow className="form-row">
          <CCol md="3">
            <CFormLabel htmlFor="lastname">Apellido</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              defaultValue={user.lastname}
              id="lastname"
              placeholder="Apellido"
              name="lastname"
              onChange={formik.handleChange}
              {...getValidity(formik.values.lastname, formik.errors.lastname)}
            />
          </CCol>
        </CRow>
        <CRow className="form-row">
          <CCol md="3">
            <CFormLabel htmlFor="loginCode">
              Código de Ingreso de Empresa
            </CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              defaultValue={user.loginCode}
              id="loginCode"
              placeholder="1234"
              type="number"
              maxLength={4}
              name="loginCode"
              onChange={formik.handleChange}
              {...getValidity(formik.values.loginCode, formik.errors.loginCode)}
            />
          </CCol>
        </CRow>
        <CRow className="form-row">
          <CCol md="3">
            <CFormLabel htmlFor="userLoginCode">
              Código de Ingreso Personal
            </CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              defaultValue={user.userLoginCode}
              id="userLoginCode"
              placeholder="5423"
              maxLength={4}
              type="number"
              name="userLoginCode"
              onChange={formik.handleChange}
              {...getValidity(
                formik.values.userLoginCode,
                formik.errors.userLoginCode
              )}
            />
          </CCol>
        </CRow>
        {!itsMe && (
          <CRow className="form-row mt-3">
            <CCol md="3">
              <CFormLabel htmlFor="userAccess">Accesos</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CRow className="d-flex w-full align-items-center">
                {(currentUser?.isAdmin ? adminUserAccess : userAccess).map(
                  (access) => (
                    <CCol md="2" key={access.id}>
                      <CFormCheck
                        defaultChecked={formik.values.userAccess.includes(
                          access.value
                        )}
                        name="userAccess"
                        id={access.value}
                        onChange={(e) => {
                          handleAccessChange(e.target.value, e.target.checked);
                        }}
                        label={access.label}
                        value={access.value}
                      />
                    </CCol>
                  )
                )}
              </CRow>
            </CCol>
          </CRow>
        )}
      </CCol>
    </>
  );
};

export default UserDetail;
