import { useMutation } from "@apollo/client";
import {
  CCol,
  CRow,
  CFormInput,
  CFormLabel,
  CFormSelect,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";
import Api from "src/api";
import { CompanyUpdate } from "src/api/companies";
import { getValidity } from "src/helpers/validation";

const FormTab = ({ company, onLoading }, ref) => {
  const navigate = useNavigate();

  const [updateMutation, { loading: updating }] = useMutation(
    Api.Companies.UPDATE_COMPANY,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [deleteMutation, { loading: deleting }] = useMutation(
    Api.Companies.DELETE_COMPANY,
    {
      onCompleted: () => {
        navigate("/companies");
      },
    }
  );

  const onDelete = useCallback(() => {
    if (!deleting && !updating) {
      deleteMutation({
        variables: {
          id: company.id,
        },
      });
    }
  }, [company.id, deleting, deleteMutation, updating]);

  const formik = useFormik<CompanyUpdate>({
    initialValues: company,
    onSubmit: (input) => {
      if (!deleting && !updating) {
        updateMutation({
          variables: {
            id: company.id,
            input: {
              name: input.name.trim(),
              legalName: input.legalName.trim(),
              streetName: input.streetName.trim(),
              streetNumber: input.streetNumber.trim(),
              description: input.description?.trim(),
              isFranchise:
                typeof input.isFranchise === "string"
                  ? input.isFranchise === "1"
                  : input.isFranchise,
              utid: input.utid?.trim(),
            },
          },
        });
      }
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      submit: () => formik.handleSubmit(),
      delete: () => onDelete(),
    }),
    [formik, onDelete]
  );

  useEffect(() => {
    onLoading(deleting);
  }, [deleting, onLoading]);

  useEffect(() => {
    onLoading(updating);
  }, [updating, onLoading]);

  return (
    <>
      <CCol xs="12">
        <CRow className="form-row">
          <CCol md="3">
            <CFormLabel htmlFor="name">Nombre</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              id="name"
              placeholder="Nombre"
              defaultValue={formik.values.name}
              name="name"
              onChange={formik.handleChange}
              {...getValidity(formik.values.name, formik.errors.name)}
            />
          </CCol>
        </CRow>
        <CRow className="form-row">
          <CCol md="3">
            <CFormLabel htmlFor="legalName">Razón Social</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              id="legalName"
              placeholder="Razón Social"
              defaultValue={formik.values.legalName}
              name="legalName"
              onChange={formik.handleChange}
              {...getValidity(formik.values.legalName, formik.errors.legalName)}
            />
          </CCol>
        </CRow>
        <CRow className="form-row">
          <CCol md="3">
            <CFormLabel htmlFor="streetName">Calle</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              id="streetName"
              placeholder="Calle"
              defaultValue={formik.values.streetName}
              name="streetName"
              onChange={formik.handleChange}
              {...getValidity(
                formik.values.streetName,
                formik.errors.streetName
              )}
            />
          </CCol>
        </CRow>
        <CRow className="form-row">
          <CCol md="3">
            <CFormLabel htmlFor="streetNumber">Altura</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              id="streetNumber"
              placeholder="Altura"
              defaultValue={formik.values.streetNumber}
              name="streetNumber"
              onChange={formik.handleChange}
              {...getValidity(
                formik.values.streetNumber,
                formik.errors.streetNumber
              )}
            />
          </CCol>
        </CRow>
        <CRow className="form-row">
          <CCol md="3">
            <CFormLabel htmlFor="utid">CUIT</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              id="utid"
              placeholder="CUIT"
              defaultValue={formik.values.utid}
              name="utid"
              onChange={formik.handleChange}
              {...getValidity(formik.values.utid, formik.errors.utid)}
            />
          </CCol>
        </CRow>
        <CRow className="form-row">
          <CCol md="3">
            <CFormLabel htmlFor="description">Descripción</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              id="description"
              placeholder="Descripción"
              defaultValue={formik.values.description}
              name="description"
              onChange={formik.handleChange}
              {...getValidity(
                formik.values.description,
                formik.errors.description
              )}
            />
          </CCol>
        </CRow>

        <CRow className="form-row">
          <CCol md="3">
            <CFormLabel htmlFor="isFranchise">Franquicia</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormSelect
              id="isFranchise"
              defaultValue={formik.values.isFranchise ? 1 : 0}
              name="isFranchise"
              onChange={formik.handleChange}
              {...getValidity(
                formik.values.isFranchise,
                formik.errors.isFranchise
              )}
            >
              <option value="0">No</option>
              <option value="1">Si</option>
            </CFormSelect>
          </CCol>
        </CRow>
      </CCol>
    </>
  );
};

export default forwardRef(FormTab);
